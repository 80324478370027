<template>
  <aside>
    <template v-for="(section, index) in sections" :key="index">
      <h2 class="font-bold">{{ section.title }}</h2>
      <ul :class="section.classes">
        <li v-for="(link, index) in section.links" :key="index" class="mb-2">
          <router-link v-if="link.type === 'router-link'"
                       class="link"
                       :to="link.path">
            {{ link.text }}
          </router-link>

          <a v-if="link.type === 'link'"
             class="link"
             :href="link.href"
             :target="link.target">
            {{ link.text }}
          </a>
        </li>
      </ul>
    </template>
  </aside>
</template>

<script>
export default {
  name: "LeftSideBar",
  data() {
    return {
      sections: [
        {
          title: "First section",
          classes: "p-2 mb-4",
          links: [
            {
              type: "router-link",
              text: "Home",
              path: "/"
            },
            {
              type: "router-link",
              text: "About",
              path: "/about"
            },
          ]
        },
        {
          title: "Second section",
          classes: "p-2 mb-4",
          links: [
            {
              type: "link",
              text: "CERN (new tab)",
              href: "https://cern.ch",
              target: "_blank"
            },
            {
              type: "link",
              text: "CERN (same tab)",
              href: "https://cern.ch",
              target: "_top"
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>