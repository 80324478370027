<template>
  <div id="main-container">
    <router-view name="TopNavbar"
                 class="fixed left-0 top-0 right-0 h-12"></router-view>
    <router-view name="LeftSidebar"
                 class="fixed left-0 top-12 bottom-0 w-56 p-4
                        border-r border-gray-400 overflow-y-auto"></router-view>
    <router-view class=""></router-view>
  </div>
</template>

<style>


#main-container {
  @apply antialiased font-sans
}

.router-link-exact-active {
  @apply font-bold text-green-500
}

a:hover {
  @apply text-green-500
}
</style>
