import { createRouter, createWebHistory } from 'vue-router'
import TopNavbar from '../views/TopNavbar.vue'
import LeftSidebar from '../views/LeftSidebar.vue'
import Home from '../views/Home.vue'
import Creations from '../views/Creations.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
//      TopNavbar,
//      LeftSidebar,
      default: Home
    }
  },
  {
    path: '/creations',
    name: 'Creations',
    components: {
      TopNavbar,
      LeftSidebar,
      default: Creations
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
 //    component: () => import(/* webpackChunkName: "about" */ '../views/Creations.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
