<template>
  <div class="ml-56 p-4 mt-12">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
export default {
  name: 'Creations'
}
</script>

<style scoped>
</style>