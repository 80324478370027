<template>
  <div class="fixed top-0 bottom-0 left-0 right-0 nebula bg-cover bg-center overflow-y-auto"
       style="">

    <div class="w-full sm:w-144 block p-8 sm:pr-0">
      <img class="w-full" src="../assets/Planet_C_Logo.png">
    </div>

    <div id="links"
         class="fixed left-8 right-8 bottom-24
                sm:sticky sm:ml-8 sm:w-160 sm:top-0 sm:bottom-0"
    >
      <ul class="text-xl sm:text-3xl sm:list-disc list-inside text-white"
          style="font-family: 'Chromosome Light', serif"
      >
        <li class="w-full">
          <a href="https://nica-grafik.ch"
             target="_blank"
             class="rounded-xl bg-gray-800 text-gray-800 text-center w-full inline-block sm:inline mb-4
                      sm:bg-transparent p-2 sm:p-0 sm:text-left sm:mb0"
          >nica-grafik.ch</a>
        </li>
        <li class="w-full">
          <a href="https://planet-c.notion.site/NICA-Image-Sculpture-5ebf56005564498f8a8cab244b1c250b"
             target="_blank"
             class="rounded-xl bg-gray-800 text-gray-800 text-center w-full inline-block sm:inline mb-4
                      sm:bg-transparent p-2 sm:p-0 sm:text-left sm:mb0"
          >nica image & sculpture</a>
        </li>
        <li>
          <a href="https://cavalvita.ch" target="_blank"
               class="rounded-xl bg-gray-800 text-gray-800 text-center w-full inline-block sm:inline mb-4
                      sm:bg-transparent p-2 sm:p-0 sm:text-left sm:mb0"
        >cavalvita.ch</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.nebula {
  background-image: url('../assets/OrionNebula_web.jpg')
}

a.inactive {
  pointer-events: none;
}

a.inactive:link, a.inactive:visited, a.inactive:active, a.inactive:focus {
  color: #aaa;
}

a:hover {
  font-weight: bold
}

a:link, a:visited, a:active, a:focus {
  color: white;
}

</style>